import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import Slideover from '../../tailwind_common/Slideover';
import { useRingStyle } from '../../tailwind_common/util';
import { InfoCircleIcon } from '../../SVG';
import CareRecSlideoverTitle from '../CareRecommendationSlideoverContent/CareRecSlideoverTitle';
import CareRecSlideoverContent from '../CareRecommendationSlideoverContent/CareRecSlideoverContent';
import './TriageRecommendationeColorMapping';
import TriageRecommendation from './TriageRecommendation';
export var TriageRecommendationWrapper = function (props) {
    var _a = useState(false), isSlideoverOpen = _a[0], setIsSlideoverOpen = _a[1];
    var isProxyUser = props.isProxyUser, theme = props.theme, primaryCareSummary = props.primaryCareSummary, whyThisResultContent = props.whyThisResultContent, conversationId = props.conversationId;
    var t = useTranslation().t;
    var ringStyle = useRingStyle();
    return (React.createElement("div", { className: "flex w-full flex-col items-center" },
        React.createElement("div", { className: "w-3/5 tablet-and-mobile:w-9/10" },
            React.createElement("h2", { className: twMerge('mb-6 flex items-end text-3xl text-gray-800 mobile:mb-4 mobile:text-2xl', 'leading-none') },
                t('common.careRecommendation', 'Care Recommendation'),
                React.createElement("button", { className: twMerge('tw-focus ml-2 flex cursor-pointer items-center rounded-full', 'h-6 w-6 focus:ring-offset-0'), style: ringStyle, onClick: function () { return setIsSlideoverOpen(!isSlideoverOpen); } },
                    React.createElement(InfoCircleIcon, { height: "24", width: "24", pathClassName: "stroke-gray-800" }))),
            React.createElement(TriageRecommendation, { isProxyUser: isProxyUser, theme: theme, primaryCareSummary: primaryCareSummary, whyThisResultContent: whyThisResultContent, conversationId: conversationId })),
        React.createElement(Slideover, { isOpen: isSlideoverOpen, setIsOpen: setIsSlideoverOpen, Title: React.createElement(CareRecSlideoverTitle, null), Content: React.createElement(CareRecSlideoverContent, null) })));
};
export default TriageRecommendationWrapper;
