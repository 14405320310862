var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { CloseIcon } from '../../../../../svg/CloseIcon';
import { registerPatient } from '../../../../../../api/TuftsCurai';
import '../../../../../clearstep/proto/conversation_pb';
import '../../../../../../api/Conversation';
import { getMyTuftsAnswerQuestionParams } from './myTuftsLoginHelpers';
import { getTreatmentWithConfig } from '../../../../../clearstep/util/Split';
import { LoadingCircle } from '../../../../../tailwind_common/LoadingCircle';
export var MyTuftsLogin = function (props) {
    var _a = useState(null), iframeUrl = _a[0], setIframeUrl = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var conversationId = props.conversationId, currentQuestion = props.currentQuestion, postAndUpdate = props.postAndUpdate, setShowMyTuftsLogin = props.setShowMyTuftsLogin;
    useEffect(function () {
        var setupIframeUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTreatmentWithConfig('tufts_login_link', { key: '' })];
                    case 1:
                        config = (_a.sent()).config;
                        if (config && config.url) {
                            setIframeUrl(config.url);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        setupIframeUrl();
    }, []);
    var onClose = useCallback(function (didLogIn) {
        var endpoint = __assign({}, currentQuestion).endpoint;
        postAndUpdate("/".concat(endpoint), getMyTuftsAnswerQuestionParams(conversationId.toString(), didLogIn));
        setShowMyTuftsLogin(false);
    }, [setShowMyTuftsLogin, currentQuestion, conversationId, postAndUpdate]);
    /*
      Putting this in useCallback instead of useEffect
      Since handleMessage is memoized with useCallback, it only changes when its
      dependencies change. This ensures that removeEventListener is called with
      the correct function reference, effectively removing the previous listener.
      By correctly adding and removing the event listener, we prevent multiple
      listeners from accumulating, avoiding memory leaks and unexpected behavior.
    */
    var handleMessage = useCallback(function (event) {
        if (event.origin === iframeUrl && typeof event.data === 'string') {
            try {
                var patient_information = JSON.parse(event.data);
                if (patient_information === null || patient_information === void 0 ? void 0 : patient_information.fhir_id) {
                    registerPatient({
                        patient_information: patient_information,
                        conversation_id: conversationId,
                    });
                    onClose(true);
                }
                else {
                    window.Sentry.captureMessage("MyTufts Login - captured event without patient information: ".concat(patient_information));
                }
            }
            catch (e) {
                /* May be receiving irrelevant messages, so capturing a message as opposed to error */
                window.Sentry.captureMessage("MyTufts Login Error: ".concat(e));
            }
        }
    }, [conversationId, onClose, iframeUrl]);
    useEffect(function () {
        window.addEventListener('message', handleMessage);
        return function () {
            window.removeEventListener('message', handleMessage);
        };
    }, [handleMessage]);
    return (React.createElement("div", { className: "fixed inset-0 z-50 h-screen w-screen bg-white" },
        React.createElement("button", { className: "absolute right-0 top-0 m-4.25 focus:rounded-full focus:shadow-focus", onClick: function () { return onClose(false); }, "aria-label": "close" },
            React.createElement("span", { className: "flex focus:rounded-full focus:shadow-focus", tabIndex: -1 },
                React.createElement(CloseIcon, null))),
        isLoading && (React.createElement("div", { className: "flex h-screen w-screen items-center justify-center" },
            React.createElement(LoadingCircle, { style: {
                    border: "10px solid ".concat(window.theme.primaryColor),
                    borderTop: "10px solid ".concat(window.theme.secondaryColor),
                } }))),
        iframeUrl && (React.createElement("iframe", { title: "MyTufts Login", src: iframeUrl, className: "h-screen w-screen", onLoad: function () { return setIsLoading(false); } }))));
};
export default MyTuftsLogin;
