"use strict";
var win = window;
if (win.Sentry) {
    win.Sentry.onLoad(function () {
        win.Sentry.init({
            integrations: [
                win.Sentry.captureConsoleIntegration({
                    levels: ['error'],
                }),
            ],
            whitelistUrls: [/.*clearstep\.health.*/],
            // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
            // run just before data is sent to Sentry basically an opportunity to clean the data
            // or not send the message at all if null is returned
            beforeSend: function (event) {
                if (win.isBot) {
                    // don't log any errors for bot it's basically noise if it's not a real user
                    // Cloudflare sets a header which determines the value set for win.isBot based
                    // on the user agent.
                    // The rule & conditions to set this value are in cloudflare:
                    // https://developers.cloudflare.com/rules/transform/request-header-modification
                    return null;
                }
                return event;
            },
        });
        if (win.sentryUser) {
            win.Sentry.configureScope(function (scope) {
                scope.setUser(win.sentryUser);
            });
        }
    });
}
