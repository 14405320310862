var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { getEnv } from './AppHelpers';
import { getPartnerName } from './Theme';
var getDate = function () {
    var date = new Date();
    var timeStamp = date.getTime();
    var day = date.getDate();
    var month = date.getMonth() + 1; // Add 1 to keep date consistent with back end splits (in js january = 0)
    return [timeStamp, day, month];
};
var addDateToConfig = function (config) {
    var _a = getDate(), timeStamp = _a[0], day = _a[1], month = _a[2];
    config.system_time = timeStamp;
    config.day = day;
    config.month = month;
    return config;
};
var addPartnerToConfig = function (config) {
    config.partner = getPartnerName();
    return config;
};
/*
  Instantiating 2 different getSplitClient() functions, getSplitUserClient() and getSplitSwitchClient()
  User client should be used when running a/b tests or %-based rollouts or any feature flag where we need
  access to the user id / conversation id

  Currently implemented to use getSplitUserClient for traffic type 'user' set in split, and
  getSplitSwitchClient for traffic type of 'switch'

  getSplitSwitchClient will call split with a single id as the key each time. It can be used in
  situations where the flag will be always on or off based on an attribute. This can be partner based,
  so if it is a flag that is on for some partners and off for others we should use this split client.
  This is to prevent us from unnecessarily using extra keys

  Note that split does not recommend instantiating more than 2 SDK clients:
  https://help.split.io/hc/en-us/articles/360058730852-Browser-SDK#instantiate-multiple-sdk-clients
*/
export var getSplitUserClient = function () {
    return new Promise(function (resolve) {
        // Retrieve split only after added to window so we don't get null pointer errors
        var checkSplitUserClient = function () { return __awaiter(void 0, void 0, void 0, function () {
            var splitUserClient;
            return __generator(this, function (_a) {
                splitUserClient = window.splitUserClient;
                if (splitUserClient) {
                    return [2 /*return*/, splitUserClient.ready().then(function () { return resolve(splitUserClient); })];
                }
                setTimeout(checkSplitUserClient, 100); // Check again after 100 milliseconds
                return [2 /*return*/];
            });
        }); };
        checkSplitUserClient();
    });
};
export var getSplitSwitchClient = function () {
    return new Promise(function (resolve) {
        // Retrieve split only after added to window so we don't get null pointer errors
        var checkSplitSwitchClient = function () { return __awaiter(void 0, void 0, void 0, function () {
            var splitSwitchClient;
            return __generator(this, function (_a) {
                splitSwitchClient = window.splitSwitchClient;
                if (splitSwitchClient) {
                    return [2 /*return*/, splitSwitchClient.ready().then(function () { return resolve(splitSwitchClient); })];
                }
                setTimeout(checkSplitSwitchClient, 100); // Check again after 100 milliseconds
                return [2 /*return*/];
            });
        }); };
        checkSplitSwitchClient();
    });
};
export var getSplitManager = function () {
    return new Promise(function (resolve) {
        // Retrieve split only after added to window so we don't get null pointer errors
        function checkSplitManager() {
            var splitManager = window.splitManager;
            if (splitManager) {
                return splitManager.ready().then(function () { return resolve(splitManager); });
            }
            setTimeout(checkSplitManager, 100); // Check again after 100 milliseconds
        }
        checkSplitManager();
    });
};
export var useIsEnabled = function (splitName) {
    var _a = useState(''), isEnabled = _a[0], setIsEnabled = _a[1];
    useEffect(function () {
        var setupIsEnabled = function () { return __awaiter(void 0, void 0, void 0, function () {
            var enabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTreatment(splitName, {})];
                    case 1:
                        enabled = _a.sent();
                        setIsEnabled(enabled);
                        return [2 /*return*/];
                }
            });
        }); };
        setupIsEnabled();
    }, []);
    return isEnabled;
};
var getAllSplits = function () { return __awaiter(void 0, void 0, void 0, function () {
    var manager;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getSplitManager()];
            case 1:
                manager = _a.sent();
                return [2 /*return*/, new Promise(function (resolve) {
                        var splits = manager.splits();
                        resolve(splits);
                    })];
        }
    });
}); };
var getTrafficType = function (splitName) { return __awaiter(void 0, void 0, Promise, function () {
    var allSplits, i, currentSplit;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getAllSplits()];
            case 1:
                allSplits = _a.sent();
                for (i = 0; i < allSplits.length; i++) {
                    currentSplit = allSplits[i];
                    if ((currentSplit === null || currentSplit === void 0 ? void 0 : currentSplit.name) === splitName &&
                        currentSplit.trafficType &&
                        !isEmpty(currentSplit.trafficType)) {
                        return [2 /*return*/, currentSplit.trafficType];
                    }
                }
                return [2 /*return*/, 'user'];
        }
    });
}); };
var getSplitClientBySplitName = function (splitName) { return __awaiter(void 0, void 0, void 0, function () {
    var trafficType;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getTrafficType(splitName)];
            case 1:
                trafficType = _a.sent();
                switch (trafficType) {
                    case 'switch':
                        return [2 /*return*/, getSplitSwitchClient()];
                    case 'user':
                        return [2 /*return*/, getSplitUserClient()];
                    default:
                        return [2 /*return*/, getSplitUserClient()];
                }
                return [2 /*return*/];
        }
    });
}); };
var getSplitClientByTrafficType = function (trafficType) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (trafficType) {
            case 'switch':
                return [2 /*return*/, getSplitSwitchClient()];
            case 'user':
                return [2 /*return*/, getSplitUserClient()];
            default:
                return [2 /*return*/, getSplitUserClient()];
        }
        return [2 /*return*/];
    });
}); };
export function getTreatmentWithConfig(splitName, config) {
    return __awaiter(this, void 0, void 0, function () {
        var splitClient, treatment, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    config = addDateToConfig(config);
                    config = addPartnerToConfig(config);
                    return [4 /*yield*/, getSplitClientBySplitName(splitName)];
                case 1:
                    splitClient = _a.sent();
                    return [4 /*yield*/, splitClient.getTreatmentWithConfig(splitName, config)];
                case 2:
                    treatment = _a.sent();
                    treatment.config = JSON.parse(treatment.config);
                    return [2 /*return*/, treatment];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, {}];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getTreatment(splitName, config) {
    return __awaiter(this, void 0, void 0, function () {
        var splitClient, treatment, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    config = addDateToConfig(config);
                    config = addPartnerToConfig(config);
                    return [4 /*yield*/, getSplitClientBySplitName(splitName)];
                case 1:
                    splitClient = _a.sent();
                    return [4 /*yield*/, splitClient.ready()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, splitClient.getTreatment(splitName, config)];
                case 3:
                    treatment = _a.sent();
                    return [2 /*return*/, treatment];
                case 4:
                    e_2 = _a.sent();
                    return [2 /*return*/, ''];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export var getSplitKey = function () {
    var env = getEnv();
    switch (env) {
        case 'dev':
            return 'euqm46qse7a9kmnrd0fcqv0i6h60k7iufhqp';
        case 'staging':
            return 'fmk57h83rjtooa3mborvbnrg6gnkta0dv1ho';
        default:
            return '8ctp4ngtm6v6q5ou9q6kmktt7r7n9ks440e4';
    }
};
var getFeatureFlagCookie = function () {
    var cookies = document.cookie.split('; ').map(function (v) { return v.split('='); });
    var flagCookie = cookies.find(function (c) { return c[0] === 'feature_flag_key'; });
    if (flagCookie) {
        return flagCookie[1];
    }
    return '';
};
export var useSplit = function (id) {
    var maxRetries = 5;
    var attempt = 0;
    var setupSplit = function () {
        var _a;
        if (!((_a = window.splitio) === null || _a === void 0 ? void 0 : _a.SplitFactory)) {
            if (attempt < maxRetries) {
                attempt += 1;
                setTimeout(setupSplit, 1000);
            }
            else {
                console.error('Split.io not available after multiple attempts.');
            }
            return;
        }
        var splitKey = getSplitKey();
        var overrideId = getFeatureFlagCookie();
        var factory = window.splitio.SplitFactory({
            core: { authorizationKey: splitKey, key: overrideId || id },
        });
        window.splitManager = factory.manager();
        window.splitUserClient = factory.client(); // conversation_id (user) traffic type
        window.splitSwitchClient = factory.client(overrideId || '49c17af5-1179-4bfa-beb8-6c2d7c124f3b'); // switch traffic type
    };
    var cleanupSplit = function () {
        var _a, _b;
        (_a = window.splitUserClient) === null || _a === void 0 ? void 0 : _a.destroy();
        (_b = window.splitSwitchClient) === null || _b === void 0 ? void 0 : _b.destroy();
        window.splitUserClient = null;
        window.splitSwitchClient = null;
    };
    setupSplit();
    return cleanupSplit;
};
export var trackEvent = function (trafficType, eventType, options) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b, eventValue, _c, properties, splitClient, queued, e_3;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = options || {}, _b = _a.eventValue, eventValue = _b === void 0 ? null : _b, _c = _a.properties, properties = _c === void 0 ? {} : _c;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getSplitClientByTrafficType(trafficType)];
            case 2:
                splitClient = _d.sent();
                queued = splitClient.track(trafficType, eventType, eventValue, properties);
                if (!queued) {
                    console.error("Unable to queue split event ".concat(eventType, ", value: ").concat(eventValue));
                }
                return [3 /*break*/, 4];
            case 3:
                e_3 = _d.sent();
                console.error("Unable to queue split event ".concat(eventType, ", value: ").concat(eventValue, "\n\nError: ").concat(e_3));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
