var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import Markdown from 'markdown-to-jsx';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { prepareText } from '../../clearstep/TextUtil';
import { renderLink } from '../../clearstep/util/MarkdownHelpers';
import { MessageType } from '../../clearstep/proto/logging_pb';
import './TriageRecommendationeColorMapping';
import { useRingStyle } from '../../tailwind_common/util';
import { DropdownIcon } from '../../svg/DropdownIcon';
import { getTreatment, trackEvent } from '../../clearstep/util/Split';
import { getTriageSummaryReason } from '../../../api/ConversationResults';
import { Reload } from '../../SVG';
import Button from '../../tailwind_common/Button';
var alertDiv = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({}, props, { role: "alert" }), children));
};
var renderSummaryText = function (primaryCareSummary, isProxyUser, options) {
    if (options === void 0) { options = {}; }
    var preparedText = prepareText(primaryCareSummary, { isProxyUser: isProxyUser });
    var _a = options.textColor, textColor = _a === void 0 ? '' : _a;
    return (React.createElement(Markdown, { options: {
            forceBlock: true,
            overrides: {
                p: function (props) {
                    props.className = 'font-semibold leading-6 text-lg lg:text-2xl lg:leading-8';
                    return alertDiv(props);
                },
                a: function (_a) {
                    var href = _a.href, children = _a.children;
                    return renderLink(href, children, MessageType.CLICKED_PARTNER_CARE_OPTION, twMerge(textColor, 'focus:underline'), {
                        extraData: { linkType: 'triage-summary' },
                    });
                },
            },
        } }, preparedText));
};
export var TriageRecommendation = function (props) {
    var isProxyUser = props.isProxyUser, theme = props.theme, primaryCareSummary = props.primaryCareSummary, conversationId = props.conversationId;
    var _a = useState(''), whyThisResultTreatment = _a[0], setWhyThisResultTreatment = _a[1];
    var _b = useState(props.whyThisResultContent), whyThisResultContent = _b[0], setWhyThisResultContent = _b[1];
    var _c = useState(false), dropdownIsExpanded = _c[0], setDropdownIsExpanded = _c[1];
    var STATUS = {
        ERROR: 'error',
        LOADING: 'loading',
        EMPTY: '',
    };
    var _d = useState(STATUS.EMPTY), getContentStatus = _d[0], setGetContentStatus = _d[1];
    var t = useTranslation().t;
    var ringStyle = useRingStyle();
    var _e = theme || {}, backgroundColor = _e.backgroundColor, textColor = _e.textColor;
    useEffect(function () {
        var setupWhyThisResultTreatment = function () { return __awaiter(void 0, void 0, void 0, function () {
            var treatment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTreatment('show_why_this_result', {})];
                    case 1:
                        treatment = _a.sent();
                        setWhyThisResultTreatment(treatment);
                        return [2 /*return*/];
                }
            });
        }); };
        setupWhyThisResultTreatment();
    }, []);
    var getContent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var conversationId, contentResp;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setGetContentStatus(STATUS.LOADING);
                    conversationId = props.conversationId;
                    return [4 /*yield*/, getTriageSummaryReason(conversationId)];
                case 1:
                    contentResp = _d.sent();
                    if (!contentResp ||
                        contentResp.error ||
                        ((_a = contentResp.data) === null || _a === void 0 ? void 0 : _a.error) ||
                        !((_b = contentResp.data) === null || _b === void 0 ? void 0 : _b.triageSummaryReason)) {
                        setGetContentStatus(STATUS.ERROR);
                        return [2 /*return*/];
                    }
                    setWhyThisResultContent((_c = contentResp.data) === null || _c === void 0 ? void 0 : _c.triageSummaryReason);
                    setGetContentStatus(STATUS.EMPTY);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var getWhyThisResultContent = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (whyThisResultContent) {
                    // If loading a prior convo with content already generated,
                    // (i.e. triageResponse.triageSummaryReason), no need to re-generate
                    return [2 /*return*/];
                }
                if (!whyThisResultTreatment.includes('why_this_result')) {
                    return [2 /*return*/];
                }
                getContent();
                return [2 /*return*/];
            });
        }); };
        getWhyThisResultContent();
    }, [whyThisResultTreatment]);
    return (React.createElement("div", { className: twMerge('flex w-full flex-col rounded-md', backgroundColor, textColor) },
        React.createElement("div", { className: "flex items-center p-5 text-lg font-extrabold leading-none lg:text-2xl mobile:p-4", "data-cy": "triageRecommendation" }, renderSummaryText(primaryCareSummary, isProxyUser, { textColor: textColor })),
        !isEmpty(conversationId) && whyThisResultTreatment.includes('why_this_result') && (React.createElement("div", { className: "border-x-0 border-b-0 border-t border-solid border-gray-400" },
            React.createElement("button", { className: "tw-focus-keyboard-only flex w-full items-center justify-between p-5 text-base text-gray-700 mobile:p-4", style: ringStyle, onClick: function () {
                    var expanding = !dropdownIsExpanded;
                    if (expanding) {
                        trackEvent('user', 'expand_triage_info_section', {});
                    }
                    setDropdownIsExpanded(!dropdownIsExpanded);
                } },
                t('whyThisResult', 'Why this result?'),
                React.createElement(DropdownIcon, { className: twMerge('flex transform transition-all duration-360 ease-in-out', dropdownIsExpanded && 'rotate-180') })),
            dropdownIsExpanded && (React.createElement("div", { className: "px-5 pb-5 text-base text-gray-500 mobile:p-4" },
                getContentStatus === STATUS.LOADING && (React.createElement("div", { className: "grid animate-pulse grid-cols-3 gap-4" },
                    React.createElement("div", { className: "col-span-3 mb-2 h-3 rounded bg-gray-200" }),
                    React.createElement("div", { className: "col-span-2 mb-2 h-3 rounded bg-gray-200 mobile:col-span-3" }),
                    React.createElement("div", { className: "hidden h-3 rounded bg-gray-200 mobile:col-span-2 mobile:grid" }))),
                getContentStatus === STATUS.EMPTY &&
                    prepareText(whyThisResultContent, { isProxyUser: isProxyUser }),
                getContentStatus === STATUS.ERROR && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "mb-3" }, t('whyThisResult.error', "We're having trouble loading the explanation at the moment. We apologize for the inconvenience.")),
                    React.createElement(Button, { secondary: true, className: "border-gray-300 bg-white hover:bg-white", innerClassName: "flex-row text-gray-700 items-center", onClick: getContent },
                        React.createElement(Reload, { className: "mr-2" }),
                        t('reload', 'Reload'))))))))));
};
export default TriageRecommendation;
