import React, { useContext } from 'react';
import { isEmpty } from 'lodash-es';
import { SourceOfCare } from '../proto/results_pb';
import '../Types';
import { isDemo } from '../util/AppHelpers';
import { RightArrow } from '../../svg/Arrows';
import CareOptions from '../../tailwind_clearstep/CareOptions/CareOptions';
import Education from '../Education/Education';
import { PartnerCustomizationContext } from '../../tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import { TriageRecommendation } from '../TriageRecommendation/TriageRecommendation';
import TriageRecommendationWrapper from '../../tailwind_clearstep/TriageRecommendation/TriageRecommendationWrapper';
import styles from './SymptomCheckResults.css';
import '../proto/conversation_pb';
import '../ConversationResults/ConversationResults';
import { getNewTriageDesignColor } from '../../tailwind_clearstep/TriageRecommendation/TriageRecommendationeColorMapping';
var SymptomCheckResults = function (props) {
    var _a;
    var complaint = props.complaint, conversationId = props.conversationId, age = props.age, onSearchCareLocations = props.onSearchCareLocations, careLocations = props.careLocations, careLocationsError = props.careLocationsError, recommendedCare = props.recommendedCare, userEnterredZipCode = props.userEnterredZipCode, isProxyUser = props.isProxyUser, conversationType = props.conversationType, existDiagnosticResources = props.existDiagnosticResources, searchCareLocationsRequestData = props.searchCareLocationsRequestData, setRequestData = props.setRequestData;
    var triageResponse = recommendedCare.triageResponse;
    var bestCareType = triageResponse.bestCareType, education = triageResponse.education;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var fetchPharmacyLocation = bestCareType !== SourceOfCare.Type.HOME;
    var newTriageDesignColor = getNewTriageDesignColor(triageResponse.triageLevel);
    var showNewTriageDesign = !isEmpty(newTriageDesignColor);
    return (React.createElement("div", { className: styles.symptomCheckResults },
        showNewTriageDesign ? (React.createElement(TriageRecommendationWrapper, { isProxyUser: isProxyUser, theme: newTriageDesignColor, primaryCareSummary: (triageResponse === null || triageResponse === void 0 ? void 0 : triageResponse.primaryCareSummary) || '', whyThisResultContent: triageResponse === null || triageResponse === void 0 ? void 0 : triageResponse.triageSummaryReason, conversationId: conversationId })) : (React.createElement(TriageRecommendation, { triageResponse: triageResponse, isProxyUser: isProxyUser, conversationId: conversationId })),
        React.createElement("div", { className: styles.symptomCheckResults__body },
            (triageResponse === null || triageResponse === void 0 ? void 0 : triageResponse.careOptions) && ((_a = triageResponse.careOptions) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(CareOptions, { conversationId: conversationId, complaint: complaint, age: age, recommendedCare: recommendedCare, onSearchCareLocations: onSearchCareLocations, careLocations: careLocations, careLocationsError: careLocationsError, userEnterredZipCode: userEnterredZipCode, fetchPharmacyLocation: fetchPharmacyLocation, isNewPatient: true, conversationType: conversationType, existDiagnosticResources: existDiagnosticResources, searchCareLocationsRequestData: searchCareLocationsRequestData, setRequestData: setRequestData, showNewTriageDesign: showNewTriageDesign })),
            education && React.createElement(Education, { education: education, isProxyUser: isProxyUser }),
            !(triageResponse === null || triageResponse === void 0 ? void 0 : triageResponse.hideDisclaimerAcknowledgment) && partnerCustomizations.disclaimer(),
            isDemo() && (React.createElement("div", { className: styles.symptomCheckResults__viewSoapNote },
                React.createElement("a", { className: styles.symptomCheckResults__viewSoapNoteLink, href: "/soap_note?conversation_id=".concat(conversationId), rel: "noopener noreferrer", target: "_blank" }, 
                // eslint-disable-next-line react/jsx-no-literals
                'View SOAP Note'),
                React.createElement(RightArrow, null))))));
};
export default SymptomCheckResults;
